.sidemenumaindiv {
  /* background: linear-gradient(180deg, #4453d6 0%, #571b65 100%); */
  mix-blend-mode: normal;
  /* width: 460px; */
  height: calc(100vh - 123px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.RecentlyViewed {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;
  padding: 5px;
  margin-bottom: 19px;
  color: #ffffff;
  opacity: 0.8;
}
.detailsdivforside {
  width: 404px;
  height: 110px;
  background: #17171d99;
  border-radius: 10px;
  margin: auto;
  padding: 10px 20px 10px 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.Siemensside {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
}
.DateoMaside {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0px;
  display: inline-block;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.locationSide {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0px;
  display: inline-block;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.priceValueText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: right;
  color: #ffffff;
  margin-bottom: 0;
}
.diveidersiddemenu {
  width: 404px;
  height: 0px;
  border: 1px solid #f5d028;
  margin: auto;
  margin-top: 41px;
}
.MyLatessidem {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  padding-top: 31px;
  padding-bottom: 15px;
}
.feedbacksidemenudiv {
  width: 403px;
  height: 61px;
  background: #8b2fa0;
  border-radius: 30px;
  margin-top: 32px;
  margin: auto;
  padding: 5px 0;
  display: flex;
}

.descriptionFeedback {
  align-items: left;
  padding-left: 15px;
  flex: 1 1;
  height: 100%;
  align-self: center;
}

.starRating {
  justify-content: flex-end;
  padding-right: 25px;
  align-self: center;
}

.dv-star-rating-star {
  margin-bottom: 0;
}

.dv-star-rating-empty-star {
  margin-bottom: 0;
}

.Beverlysidemen {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
}
.Ordersedeminr {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.8;
}
.SearchMarksidemeni {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
}
.searchmenuinptsidemn {
  width: 346px;
  height: 48px;
  background: #17171d;
  opacity: 0.5;
  border-radius: 30px;
  display: flex;
  align-items: center;
}
.tersearchsidemneu {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  background: transparent;
  border: none;
  width: 330px;
}

.tersearchsidemneu.form-control:focus {
  color: #ffffff;
  background-color: transparent !important;
}
.filtclesedemni {
  background: #17171d;
  opacity: 0.8;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  text-align: center;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.browseMarketplaceWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 9px;
  width: 404px;
  margin: 0 auto;
}

.browseMarketplaceWrapper .p-dropdown {
  margin: 0;
  width: 48%;
}

.browseMarketplaceWrapper .p-multiselect {
  width: 50%;
}

.browseMarketplaceWrapper .p-multiselect-label-container {
  width: 100%;
}

.browseMarketplaceWrapper .p-multiselect:last-child {
  margin-right: 0 !important;
}

.browsemarkwsedmenu {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  /* opacity: 0.8; */
  padding-top: 31px;
}
.browsebuttondisdem {
  background: #f5d028 !important;
  border-radius: 30px;
  width: 115.6px;
  height: 33.32px;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #333333 !important;
  margin-top: 9px;
  line-height: 0px;
}

.browsebuttondisdemWrapper {
  width: 404px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}

.quiringActionsedemn {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
}

.ordersrequiresedmen {
  width: 404px;
  min-height: 110px;
  background: #17171d99;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  margin: auto;
  margin-top: 19px;
  cursor: pointer;
}

.Siemenssedmekjpr {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  margin: 0px;
}
.OrderID10sefv {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0px;
}
.Awaitinsefkrnf {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;

  display: flex;
  align-items: baseline;
}
.OrderPlacecdcdfvfd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0px;
}
.US7scsdvf500 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: right;
  color: #ffffff;
}
.greenlightsedemnei {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: #4aea87;
  margin-right: 5px;
}
.nosellerFeedback {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
}

.marketficlosecircleMenu {
  width: 49px;
  height: 49px;
  border-radius: 49px;
  background: #4453d6;
  text-align: center;
  margin: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}
.marketficlosecircleMenu {
  position: relative;
  z-index: 101;
}
/* 
.marketBrowseinput {
  background: #17171d !important;
  border-radius: 30px !important;
  width: 235px;
  height: 40px;
  border: none !important;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 10px;
  margin-right: 15px;
}
.marketBrowseinput .p-dropdown-trigger {
  background: #17171d !important;
  color: #ffffff !important;
  border-radius: 30px !important;
}
.marketBrowseinput .body .p-dropdown .p-dropdown-trigger {
  background: #17171d !important;
  color: #ffffff !important;
  border-radius: 30px !important;
}
.marketBrowseinput label,
.marketBrowseinput label,
.marketBrowseinput label,
.marketBrowseinput .p-dropdown-panel {
  background: #17171d !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  margin-top: 3px;
}
.marketBrowseinput li {
  color: #ffffff !important;
  border-radius: 10px;
  font-size: 15px !important;
} */


.marketBrowseinputDropdown {
  border-radius: 30px ;
  outline: none;
  height: 34px;
  width: 195px !important;
  padding: 20px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  background: #17171D;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-right: 10px;
}
.marketBrowseinputContainerDropdown {
  color: #17171D;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 34px;
  width: 195px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.browseMarketplaceWrapper .openDropdownActiveBg{
  background: #17171D !important;
}
.marketBrowseinputContainerDropdown .selectedDropdownItem{
  background: #4453D6;
}


.marketSearchFilterinputDropdown {
  border-radius: 30px;
  outline: none;
  height: 35px;
  width: 235px !important;
  padding: 20px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  background: #4453D6;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-right: 10px;
}
.marketSearchFilterinputContainerDropdown {
  color: #17171D;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 35px;
  width: 235px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.marketSearchFilterinputDropdown > .select-box--selected-item{
  width: 100%;
  text-align: center;
}
.marketSearchFilterinputContainerDropdown > div{
  padding-left: 0px !important;
}