// Mixins
@mixin inset {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

@mixin progress-bar {
  width: 100%;
  height: 8px;
  margin-top: 0;
  background-color: $grey;
  border-radius: 10px;
}

@mixin button {
  width: 100%;
  max-width: 327px;
  height: 49px;
  border-radius: 30px;
  font-size: 16px;
  color: $white;
  opacity: 0.8;
  padding-left: 60px;
  border: none;
}

@mixin container {
  width: 100%;
  margin: 0 auto;
}

@mixin title {
  font-size: 15px;
  color: $white;
  margin: 0 0 4px;
  align-self: flex-start;
  padding-left: 10px;
}

@mixin setup-acc {
  align-self: flex-start;
  font-size: 20px;
  color: $grey;
}

@mixin form-button {
  height: 49px;
  background: $dark-background;
  // opacity: 0.8;
  border-radius: 30px;
  font-size: 16px;
  color: $white;
  border: none;
}

@mixin dropdown-trigger {
  background: transparent;
  color: $white;
  margin-right: 8px;
  border-radius: 100%;
  height: 40px;
  margin-top: 4px;
}

@mixin dropdown-label {
  background: transparent;
  color: $white;
  padding-top: 12px;
  font-size: 15px;
  border-radius: 30px;
  opacity: 0.5;
  padding-left: 20px;
}

@mixin psuedo-container {
  // overflow: hidden;
  // overflow-y: scroll;
  margin: 0 auto;
  padding: 1.9rem;
  height: 100%;
  position: relative;
  background-color: transparent;
  z-index: 1;
}
@mixin psuedo-container-before {
  content: '';
  @include inset();
  overflow: hidden;
  // overflow-y: scroll;
  position: absolute;
  height: 100%;
  margin: 0 auto;
  padding: 1.9rem;
  height: 100%;
  background: $dark-background;
  opacity: 0.5;
  border-radius: 30px;
  z-index: -1;
}

// Variables
// Colors
$white: #fff;
$grey: #c4c4c4;
$dark-background: #17171d;
$progress-blue: #4658d4;
$button-blue: #4453d6;
// Breakpoints
$breakpoint-small: 600px;

// To overide font-family Helvetica
.div-flex,
#registercontainer {
  font-family: Segoe;
  #registerstep1logo {
    text-align: center;
  }
  // overflow-y: scroll;
  #loginScreen3 {
    .logo {
      padding-bottom: 1.9rem;
    }
  }
  #registercontainer1 {
    height: 100%;
    padding-bottom: 1.9rem;
  }
  .maineSingupContaner {
    margin: 0 auto;
    height: 80%;
    width: 100%;
    max-width: 706px;
    overflow-y: scroll;
    // max-height: 755px;
    // overflow-y: scroll;
    // overflow-x: auto;

    .maineSingupContanerPaddingTop {
      height: 100%;
      width: 100%;
      #registerstep1 {
        height: 100%;
      }
      .signupstep1::before {
        @include psuedo-container-before();
        min-height: 657px;
        max-width: 387px;
      }
      .signupstep1 {
        @include psuedo-container();
        max-height: 657px;
        overflow-x: hidden;
        overflow-y: auto;
        max-width: 387px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;

        .setupacc2 {
          @include setup-acc();
          margin-bottom: 6px;
        }
        #registerstep13 {
          width: 100%;
          margin-bottom: 17px;
          .profgress,
          .profgress2 {
            @include progress-bar();
          }
          // to overide margin-top values
          #progress2 {
            margin-top: 0;
          }
        }
        .Createlo {
          font-size: 18px;
          color: $white;
          // margin-bottom: 1px;
        }
        #registerstep15 {
          width: 100%;
          margin-bottom: 12px;
          .title2 {
            @include title();
          }
          .relative {
            position: relative;
            @include container();
            .signUpinputIcons {
              position: absolute;
              top: 25px;
              transform: translateY(-50%);
              z-index: 1;
              opacity: 0.5;
            }
            .step1inpu {
              @include button();
              background: $dark-background;
              // margin-bottom: 12px;
            }
          }
        }
        .password-again-continer {
          @include container();
          margin-bottom: 12px;
          .title3 {
            @include title();
          }
          #registerpassagaininput {
            @include button();
            background: $dark-background;
            // margin-bottom: 11px;
          }
        }
        .phone-container {
          @include container();
          .title3 {
            @include title();
          }
          .phoneInputContainer {
            position: relative;

            .phoneInputBox::before {
              content: '';
              position: absolute;
              background: $dark-background;
              border-radius: 30px;
              // opacity: 0.7;
              width: 100%;
              height: 100%;
              z-index: -1;
            }
            .phoneInputBox {
              display: inline-flex;
              width: 100%;
              max-width: 327px;
              border-radius: 30px;
              height: 49px;
              position: relative;
              z-index: 11;
              .phoneInput {
                height: 49px;
                background-color: transparent;
                font-size: 16px;
                color: $white;
                opacity: 0.7;
                border: none;
              }
              .selectCountryButton {
                font-size: 16px;
                background-color: transparent;
                color: $white;
                border: none;
                border-radius: 20px;
                height: 49px;
                display: inline-flex;
                min-width: 100px;
                width: max-content;
                opacity: 0.8;
                align-items: center;
                padding-left: 30px;
              }
            }
          }
        }
      }

      #registerBottomBox {
        @include container();
        // margin-bottom: 25px;
        .stepnextbu {
          @include button();
          background-color: $button-blue;
          padding: 0;
          margin-bottom: 15px;
        }
      }
      .termsandCondition {
        font-size: 11px;
        text-align: center;
        color: $white;
        // display: flex;
        align-items: center;
        margin: 15px 0;
        a {
          color: $white;
        }
        a:hover {
          color: $white;
          text-decoration: underline;
        }
      }
      .Already {
        font-size: 16px;
        text-align: center;
        color: $white;
        a {
          text-decoration: none;
          color: $white;
          font-weight: bold;
        }
        a:hover {
          text-decoration: none;
          color: $white;
        }
      }
    }
  }
}

.countryListDropdown {
  display: block;
  position: absolute;
  width: 327px !important;
  overflow: hidden;
  /* overflow-y: scroll; */
  background: $dark-background;
  border-radius: 10px;
  z-index: 10;
  padding: 10px;
  top: 60%;
  padding-top: 30px;
  .searchInput {
    position: sticky;
    width: 100%;
    padding-left: 45px;
    color: #ffffff;
    top: 0;
  }
  .searchInputField {
    border-radius: 10px;
    width: 100%;
    padding-left: 15px;
    color: #ffffff;
    top: 0;
    background: $button-blue;
    border-radius: 30px;
    height: 29.89px;
    width: 212px;
  }
  .searchInputField::placeholder {
    color: $white;
  }
  .countryListDropdownScroller {
    width: 100%;
    height: 225px;
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  div {
    font-size: 16px;
    color: $white;
    cursor: pointer;
    padding: 5px;
    text-align: left;
    // display: flex;
    display: -webkit-inline-box;
    align-items: center;
  }

  .countryListDropdownScroller::-webkit-scrollbar {
    width: 8px;
  }
  .countryListDropdownScroller::-webkit-scrollbar-thumb {
    background: #4453d6;
    border-radius: 20px;
  }
  .countryListDropdownScroller::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 10px;
  }
}

// Media Queries
// Mobile
@media (max-width: $breakpoint-small) {
  // Register back button is clashing with content on mobile view
  .div-flex {
    #registerbackButton .signup-step-goback-container,
    .signup-bold,
    .signup-normal {
      display: none;
    }
  }
}

// Go back button
.signupStepGoback {
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: $grey;
  background: transparent;
  border: none;
  position: absolute;
  right: 25px;
  // padding: 60px 20px 0 0;
  .signup-bold {
    font-weight: bold;
    margin-left: 5px;
  }
}
.signupStepGoback div {
  width: 25px;
  height: 26px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 5px;
  text-align: center;
  // padding-top: 2px;
}
.signupStepGoback img {
  width: 14px;
  height: 14px;
}
.signupStepGoback:hover {
  background: transparent;
}

// Register SMS Page
#registercontainer {
  position: relative;
  .maineSingupContaner {
    #registerbackButton {
      position: absolute;
      z-index: 2;
      right: 0;
    }
    #registerStep2 {
      height: 100%;
      width: 100%;
      #registerStep22 {
        padding-bottom: 1.9rem;
        text-align: center;
      }
      #registerStep23 {
        div {
          margin-bottom: 10px;
        }
      }

      .signupstep1:before {
        @include psuedo-container-before();
      }
      .signupstep1 {
        @include psuedo-container();
        max-width: 387px;
        max-height: 539px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .setupacc2 {
          @include setup-acc();
        }
        #registerStep25 {
          width: 100%;
          .profgress,
          .profgress2 {
            @include progress-bar();
            .progress-bar {
              width: 66%;
              height: 8px;
              background-color: $progress-blue;
            }
          }
          .profgress2 .progress-bar,
          .profgress .progress-bar {
            background-color: $progress-blue;
          }
        }
        #registerStep2ChangePhone {
          margin-top: 0;
          margin-top: 0;
          width: 100%;
          display: flex;
          flex-grow: 0.5;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          .title3 {
            @include title();
            margin-bottom: 20px;
            padding-left: 0;
            text-align: center;
          }
          .changePhoneText {
            @include title();
          }
          .cantchangePhoneText {
            @include title();
          }
          .stepnextbu {
            @include button();
            background-color: $button-blue;
            padding: 0;
          }
          #registerStep2Resend {
            @include button();
            padding: 0;
          }
          .opt-mobile-container {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            .otpMobileEllipse {
              margin-bottom: 0;
              display: flex;
              width: 100%;
              max-width: 49px;
              align-items: center;
              justify-content: center;
              height: 49px;
              border-radius: 30px;
              background-color: $white;
              color: #333333;
              text-align: center;
            }
            .step1inpu {
              width: 80%;
              height: 49px;
              background: #17171d;
              border-radius: 30px;
              font-size: 16px;
              color: $white;
              opacity: 0.8;
              padding-left: 40px;
              border: none;
            }
          }
        }
        #registerStep26 {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;
          .title3 {
            @include title();
          }

          .stepnextbu {
            @include button();
            padding: 0;
            background-color: $button-blue;
          }
          #registerStep27 {
            margin: 0 auto;
            width: 100%;
            #registerStep28 {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 12px;
              .selectCountryButton {
                font-size: 16px;
                background: transparent;
                color: $white;
                border: none;
                border-radius: 20px;
                height: 49px;
                display: inline-flex;
                opacity: 0.8;
                align-items: center;
              }
              .phoneInput {
                height: 49px;
                background-color: $dark-background;
                color: $white;
                flex-grow: 1;
                border-radius: 20px;
                padding-left: 20px;
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}

// Setup Account Page
.maineSingupContaner {
  max-width: 706px;
  #registerStep3 {
    #registerStep31 {
      text-align: center;
    }
    form {
      // overflow: hidden;
      .signmaindiv::before {
        @include psuedo-container-before();
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 539px;
      }
      .signmaindiv {
        @include psuedo-container();
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 539px;
        overflow-y: auto;

        .setupacc {
          @include setup-acc();
          padding-left: 27%;
        }
        #registerStep34 {
          width: 46%;
          margin-bottom: 15px;
        }
        .insertpersod {
          font-size: 20px;
          margin-bottom: 15px;
          color: $white;
        }
        #registerStep36 {
          width: 87%;
          margin: 0 auto;
          justify-content: space-between;
          margin-bottom: 15px;
          .inlinePictureOrButton {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            color: $white;
            flex-grow: 0.5;
            .placetexj {
              font-size: 18px;
            }
            .placetexj2 {
              font-size: 16px;
              opacity: 0.5;
            }
            .uploasscxd {
              width: 95px;
              height: 32px;
              background: #4453d6;
              border-radius: 30px;
              margin: 0;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }
        }
        .signUpProfileBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          width: 100%;
          p {
            margin-bottom: 0;
            font-size: 15px;
            color: $white;
          }
          .title {
            @include title();
          }
          .drinpu {
            @include form-button();
            .p-dropdown-trigger {
              @include dropdown-trigger();
            }
            label {
              @include dropdown-label();
            }
            .p-dropdown {
              // width: 100px;
            }
            .p-dropdown-panel .p-dropdown-items {
              background: $dark-background;
            }
            .p-dropdown-panel {
              border: none;
              margin-top: -10px;
            }
            .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
            body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
              color: $white;
            }
            .p-input-overlay-visible {
              background-color: transparent;
            }
          }
          #registerFirstname,
          .register-lastname {
            flex-grow: 0.3;
            input {
              padding-left: 30px;
            }
          }
          .nameinpu {
            width: 100%;
            height: 49px;
            background: $dark-background;
            opacity: 0.8;
            border-radius: 30px;
            font-size: 16px;
            color: $white;
            border: none;
            padding-left: 15px;
          }
        }

        // .validationerrorMessage {
        //   position: relative;
        //   z-index: 10;
        // }
        .signUpProfileBoxDOBFields {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: 'center';
          margin-bottom: 35px;
          .title {
            @include title();
          }
          .datePickerinput {
            height: 49px;
            background: $dark-background;
            opacity: 0.8;
            border-radius: 30px;
            font-size: 16px;
            color: $white;
            border: none;
            padding: 0 15px;
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          .drinpu {
            @include form-button();
            .p-dropdown-trigger {
              @include dropdown-trigger();
            }
            label {
              @include dropdown-label();
            }
            .p-dropdown {
              width: 100px;
            }
            .p-dropdown-panel .p-dropdown-items {
              background: $dark-background;
              opacity: 0.8;
              // border-radius: 30px;
            }
            .p-dropdown-panel {
              border: none;
              margin-top: -10px;
            }
            .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
            body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
              color: $white;
            }
            .p-input-overlay-visible {
              background-color: transparent;
            }
          }
        }
        .nextsignu {
          @include button();
          padding: 0;
          background-color: $button-blue;
          border-radius: 30px;
          margin-bottom: 20px;
        }
        .submit-button {
          max-width: 327px;
        }
      }
    }
  }
}

// Err Messages
.redBoxErrorMsg {
  width: 100%;
}

// Terms {
.mainContinerterms {
  .innerTermsBox {
    width: 100%;
    height: 100%;
    max-width: 429px;
    max-height: 599px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    .itemListBox {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .itemTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Segoe;
        font-size: 18px;
        width: 100%;
        max-width: 387px;
        margin: 0 auto;
        color: #ffffff;
        cursor: pointer;
        opacity: 0.8;
        border-bottom: 1px solid #ffffff;
        div {
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
          margin-bottom: 0;
        }
        img {
          width: 20px;
        }
      }
    }
  }
  .termsbackbutton {
    font-family: 'Segoe';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: inline-flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    color: #ffffff;
    background: transparent;
    border: none;
    margin-top: 0px;
  }
  .termsbackbutton:hover {
    background: transparent;
    color: #666666;
  }
  .termsbackbutton div {
    width: 25px;
    height: 25px;
    background: #4453d6;
    border-radius: 4px;
    margin-right: 10px;
    padding: 1px;
    margin-right: 5px;
    padding-right: 5px;
  }
  .termsbackbutton span {
    font-family: 'Segoe';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-left: 5px;
    color: #ffffff;
  }
  .termsbackbutton img {
    width: 15px;
  }
  .scrollContainerTerms {
    font-family: Segoe;
    height: calc(100vh - 200px);
    overflow: auto;
    padding-right: 1.9rem;
    padding-bottom: 50%;
  }
}
.innerTermsBox {
  padding: 20px;
}
.termsTitle {
  font-size: 20px;
  align-items: center;
  color: $white;
}
.boldWhiteText {
  font-weight: bold;
}
.termsDescription {
  font-size: 16px;
  align-items: center;
  color: $white;
  background: transparent;
}

// Account Created
.step3maindi::before {
  @include psuedo-container-before();
  // max-height: 657px;
  max-width: 387px;
}
.step3maindi {
  @include psuedo-container();
  // max-height: 576px;
  max-width: 387px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .setupacc2 {
    @include setup-acc();
    text-align: center;
    width: 100%;
  }
  #registerStep43 {
    height: 8px;
    width: 100%;
    margin-bottom: 43px;
    .profgress2 .progress {
      @include progress-bar();
      width: 100%;
    }
  }
  .successAccount {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    img {
      margin-bottom: 22px;
    }
    .AccountCr {
      font-size: 24px;
      color: $white;
      margin-bottom: 20px;
    }
    .Thankyoumsg {
      font-size: 16px;
      color: $white;
      text-align: center;
      margin-bottom: 25px;
      p {
        margin-bottom: 0;
      }
    }
  }
  .successAccountFooter {
    width: 100%;
    button {
      margin-bottom: 11px;
    }
    .step3buttonl,
    .Thankyoumsg .resendConfirmation {
      @include button();
      padding: 0;
      width: 100%;
      background-color: $button-blue;
      p {
        margin: 0;
      }
    }

    .startMeetingAuthButtons {
      @include button();
      padding: 0;
      width: 100%;
      background-color: $dark-background;
    }
  }
}


.registerProfileTitleDropdown {
  border-radius: 30px ;
  outline: none;
  height: 49px;
  width: 90px !important;
  padding: 15px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  background: #17171D;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  opacity: 0.8 !important;
}
.registerProfileTitleContainerDropdown {
  color: #17171D;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 49px;
  width: 90px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.registerProfileGenderDropdown {
  border-radius: 30px ;
  outline: none;
  height: 49px;
  width: 300px !important;
  padding: 15px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  background: #17171D;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  opacity: 0.8 !important;
}
.registerProfileGenderContainerDropdown {
  color: #17171D;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 49px;
  width: 300px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.registerProfileGenderContainerDropdown>div{
  padding:5px !important;
  padding-left:20px !important;

}
.registerProfileTitleContainerDropdown .selectedDropdownItem , .registerProfileGenderContainerDropdown .selectedDropdownItem {
  background: #4453D6;
}


// Scroll Issues
::-webkit-scrollbar,
.scroller {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
element::-webkit-scrollbar {
  width: 0 !important;
}

* {
  scrollbar-width: none; /* Firefox implementation */
}

.rc-anchor-content {
  margin-top: 50px;
}
.rc-anchor-error-msg-container {
  padding: 5px 0 0 5px;
}

.rc-anchor-error-msg-container {
  padding: 40px;
}

