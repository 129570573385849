body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Segoe Neue', sans-serif,
    'Segoe';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
/* body {
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Segoe",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Segoe Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/gimage.jpg");

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
body::before {
  position: absolute;
  background-color: #000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.5;
} */

body {
  height: 100%;
}

html {
  height: 100%;
}

#root {
  z-index: 2;
  height: 100%;
}
.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  left: 40.78%;
  right: 57.49%;
  top: 19.05%;
  bottom: 77.23%;
  /* height: 20vmin; */
}
input[type='file'] {
  display: none;
  background: #0c0f1d;
}
.inputError {
  color: #e74c3c !important;
  font-size: 11px !important;
}
input[type='text'] {
  /* background: #0C0F1D; */
  /* border-radius: 10px; */
  /* font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px; */
  /* line-height: 19px; */
  /* color: #ffffff; */
  border: none;
  /* height: 60px; */
}
input[type='email'] {
  background: #0c0f1d;
  border-radius: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  height: 60px;
}
/* input[type="password"] {
  background: #0C0F1D;
  border-radius: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  height: 60px;
} */
.inputSelect {
  background: #0c0f1d;
  border-radius: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border: none;
  height: 60px;
}
.forgotClass {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #4092b7 !important;
}
.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background: #4190b7;
  border-radius: 5px;
  color: #ffffff;
}
/* .Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  background: transparent !important;
}
.Toastify__toast--error {
  color: #e74c3c !important;
  background: transparent !important;


} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.forgetPageText {
  width: 459px;
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 23px !important;
  text-align: center !important;
  color: #ffffff !important;
}

.forgetTextLogin {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #4092b7;
}

.forgetButtonDiv {
  padding: 0 30px;
  display: flex;
  justify-content: center;
}
.button100Width {
  width: 100% !important;
}
.forgetText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
}
.textAlignCenter {
  text-align: center !important;
}
.emailForgotPassword {
  width: 327px !important;
}
.otpForgot {
  width: 280px !important;
}
.positionRel {
  position: relative !important;
}
.secDiv {
  background: #535660;
  /* opacity: 0.3; */
  border-radius: 5px;
  padding: 5px;
  margin-top: 11px;
  padding-top: 12px;
  width: 70px;
  position: absolute;
  right: 66px;
  top: 0px;
}
.secText {
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
}

.btn:focus,
.btn:active {
  border: 0px !important;
  box-shadow: none !important;
}

.dropdown-menu-right {
  width: 200px !important;
}
.handpointer {
  cursor: pointer;
}
.collapseIconsopen {
  width: 25px;
  transform: rotate(180deg);
}
.findbusfbutton {
  background: linear-gradient(180deg, #439fc8 0%, #4358a7 100%);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 250px;
  height: 49px;
}
.findbusaddlat {
  cursor: pointer;
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: rgb(64, 146, 183);
  padding-top: 22px;
}

button {
  border: none;
}

/* .maineSingupContaner {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-bottom: 20px;
} */
.gradientColorPurpleBlue {
  /* background: linear-gradient(313.09deg, rgba(87, 27, 101,0.52) 5.62%, rgba(68, 83, 214,0.72) 74.88%); */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: url("./images/appbackimg.png"); */
  /* background: url("./images/gimage.jpg"); */

  /* background-image:
linear-gradient(313.09deg, rgba(245, 246, 252, 0.52) 5.62%, rgba(117, 19, 93, 0.73) 74.88%),
url('./images/gimage.jpg'); */

  background-image:
    /* linear-gradient(313.09deg, rgba(87, 27, 101,0.52) 5.62%, rgba(68, 83, 214,0.72) 74.88%), */ url('./images/icons/background/purplegradient.png');
  /*
    background-image: linear-gradient(313.09deg, #571B65 5.62%, #4453D6 74.88%),
    url('./images/gimage1.jpeg'); */

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* position: relative; */
  /* z-index: 1; */
  width: 100vw;
  height: 100vh;
}
.blueBg {
  background: #4453d6 !important;
}

.lightBlackGrayBg {
  background: #333333 !important;
  height: 100vh;
}

.lightBlackGrayBg::before {
  content: '';
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: #333333;
  z-index: -1;
  /* width: 490px; */
}

/* .grayBlackBg {
  background: #333333 !important;
} */

.grayBlackBg {
  display: inline-flex;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.grayBlackBg::before {
  content: '';
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: #333333;
  z-index: -1;
  width: 490px;
}

.ContactgradientBg {
  background: linear-gradient(180deg, #4453d6 0%, #8b2fa0 100%);
}
.sideMenuGradient {
  background: linear-gradient(270deg, #4453d6 0%, #571b65 100%);
  border-radius: 0px;
}

.notificationBg {
  position: relative;
  background: url('./images/globe-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;

  /* background: linear-gradient(180deg, #4453D6 0%, #8B2FA0 100%);
  height: 100vh; */
  /* opacity: 0.1; */

  /* background-image:url('./images/gimage1.jpeg'); */
}
.notificationBg::before {
  position: absolute;

  content: '';
  /* position: fixed; */
  /* background: url('./images/globe-bg.png'); */
  background: linear-gradient(180deg, #4453d6 0%, #8b2fa0 100%);

  /* background: linear-gradient(180deg, #4453D6 0%, #8B2FA0 100%);
   */
  /* background-color: #000000; */
  /* background-image: url("./images/gimage.jpg"); */

  /* background:url('./images/globe-bg.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100vw; */
  /* height: 100vh; */
  z-index: -1;
  width: 100%;
  height: 100%;
  /* content: "";
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image:url('./images/gimage1.jpeg');
  
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.3;
  z-index: -10000; */
}
.rdeePayBg {
  background: transparent !important;
  /* background-image: url('./images/rdeePayBack.png');
  background-position: center;
    background-size: cover;
    background-repeat: no-repeat !important;
    position: relative !important;
    z-index: 1;
    height: 100%; */
  /* background: linear-gradient(180deg, #4453D6 0%, #8B2FA0 100%); */
}

@font-face {
  /* font-family: 'Segoe UI';
  src: url('./fonts/segoe/SegoeUI.ttf'); */

  font-family: 'Segoe';
  src: url('./fonts/segoe/SegoeUI.ttf');
}

.p-dropdown {
  min-width: auto !important;
  box-shadow: none !important;
}

.blackBGWithImage {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  background-image: url("./images/gimage.jpg");

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1; */
}

.blackBGWithImage::before {
  /* position: absolute; */
  content: '';
  position: fixed;
  background-color: #000000;
  background-image: url('./images/gimage.jpg');

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100vw; */
  /* height: 100vh; */
  z-index: -1;
  width: 100%;
  height: 100%;
  /* opacity: 0.5; */
}

.availableSoon {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 46px;
  text-align: center;

  color: #ffffff;
}
.innerText {
  margin: auto;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
}

.react-datepicker__navigation {
  width: 20px !important;
}

.backgroundAvailaleSoon {
  background-color: #cccccc;
  height: 100vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.centerBGimageText {
  align-items: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 46px;
  padding-top: 90px;
  color: #ffffff;
}

.loadMoreDiv {
  text-align: center;
}
.loadMoreChatButton {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 0px;
  color: #ffffff !important;
  border: none !important;
  background: #4453d6 !important;
  border-radius: 20px;
  width: 150px;
}

.arrowStyle {
  width: 25px;
  color: #ffffff;
}

.Toastify__toast-container {
  font-family: Segoe !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 29px !important;
}
.Toastify__toast {
  font-family: Segoe !important;
}
.Toastify__toast--error {
  background: #fd4b4b !important;
  border-radius: 10px !important;
  color: #ffffff !important;
}
.Toastify__toast--warning {
  border-radius: 10px !important;
  color: #ffffff !important;
}
.Toastify__toast--success {
  background: #4aea87 !important;
  border-radius: 10px !important;
  color: #17171d !important;
}
.Toastify__toast-body {
  padding: 15px;
}
.Toastify__progress-bar {
}

.Toastify__toast-container--top-center {
  top: 50% !important;
}
