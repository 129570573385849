.inVoiceMaincontainer {
  width: 100vw;
  /* height: calc(100vh - 100px); */
  /* overflow: auto; */
  padding: 10px;
  font-family: 'Segoe';
}
.inVoiceChildcontainer{

}

.invoiceSearchBoxContainer{
  background: #333333;
  border-radius: 10px;
  padding: 15px;
  max-width: 1000px;

}
.inVoiceMainTitle{
  
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 15px;

}
.invoiceListContainer{
  width:100% ;
  height: calc(100vh - 350px);
  overflow-y: scroll !important;
  overflow: hidden;
}
.invoiceList{
  width: 100%;
  display: inline-flex;
  margin: 5px;
  min-height: 40px;
  align-items: center;
}
.headerTitle{
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}
.invoiceListNameColumn{
  width: 300px;
  padding-left: 10px;
  color: #FFFFFF;
  font-weight: bold;


}
.invoiceListDateColumn{
  width: 200px;
  padding-left: 10px;
  color: #FFFFFF;


}
.downloadInvoiceButton{
  border: none !important;
  box-shadow: none !important;
  background: #4453d6 !important;
  color: #FFFFFF !important;
  font-size: 14px !important;
  border-radius: 30px !important;

}
.searchInvoiceInputField {
  border-radius: 10px;
  width: 100%;
  padding-left: 15px;
  color: #ffffff;
  background:#17171D;
  border-radius: 30px !important;
  height: 40.89px;
  width: 212px;
  border: none !important;
  margin-left: 20px;
  align-items: center;
  display: flex;
}
.searchInvoiceDatepicker{
  border-radius: 10px;
  width: 100%;
  padding-left: 15px;
  color: #ffffff;
  background:#17171D;
  border-radius: 30px !important;
  height: 40.89px;
  width: 212px;
  margin-left: 20px;
  align-items: center;
  display: flex;
  cursor: pointer;
}



.searchInvoiceLabels{
  color: #FFFFFF;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 15px;
}
.searchInvoiceLabels .calendar-container{
  bottom: 0;
}

.invoiceTypeDropdown {
  border-radius: 30px ;
  outline: none;
  height: 40px;
  width: 150px !important;
  padding: 15px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  background: #17171D;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  opacity: 0.8 !important;
}
.invoiceTypeContainerDropdown {
  color: #17171D;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 40px;
  width: 150px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.searchInvoiceButton{
  border: none !important;
  box-shadow: none !important;
  background: #4453d6 !important;
  color: #FFFFFF !important;
  font-size: 14px !important;
  border-radius: 30px !important;
  min-width: 120px !important;
  height: 45px !important;
  font-size: 16px !important;

}



@media only screen and (min-width: 300px) and (max-width: 767px) {
  /* .inVoiceMaincontainer {
    margin-top: 150px !important;
  } */
}
@media all and (min-width: 768px) and (max-width: 1024px) {
  /* .inVoiceMaincontainer {
    margin-top: 200px !important;
  } */
}

@media all and (min-width: 1025px) {
  /* .inVoiceMaincontainer {
    display: flex;
    align-items: center;
  } */
}
