.priceListcontainer {
  width: 100vw;
  height: calc(100vh - 95px);
  overflow: auto;
  /* margin-top: 100px; */
  padding: 20px;
  /* height: 100%; */
}
.priceListChildcontainer {
  width: 100%;
}
.pricing-table-subtitle {
  margin-top: 68px;
  font-weight: normal;
  font-size: 20px;
}
.pricingBoxAlignCenter {
  display: flex;
  justify-content: center;
}

.pricing-table-title {
  font-weight: bold;
  margin-bottom: 20px;
  color: #FFFFFF;
}
.priceCardBoxContainer {
  margin: 10px;
}

.pricing-card {
  border: none;
  border-radius: 10px;
  margin-bottom: 40px;
  background: #333333;
  text-align: center;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  max-width: 400px;
}
.pricing-card:hover {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}
.pricing-card.pricing-card-highlighted {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}
.pricing-card:hover {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.pricing-card .card-body {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pricing-plan-title {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 11px;
  font-weight: normal;
}

.pricing-plan-cost {
  font-size: 50px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 29px;
}

.pricing-plan-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 1;
  margin-bottom: 24px;
}
.pricing-plan-basic .pricing-plan-icon {
  color: #fe397a;
}
.pricing-plan-pro .pricing-plan-icon {
  color: #10bb87;
}
.pricing-plan-enterprise .pricing-plan-icon {
  color: #5d78ff;
}

.pricing-plan-features {
  /* list-style: none; */
  padding-left: 50px !important;
  font-size: 14px;
  line-height: 2.14;
  margin-bottom: 35px;
  color: #ffffff;
  text-align: left;
  padding: 20px;
  display: inline-flex;
  flex-wrap: wrap;
}
.pricing-plan-features li {
  width: 50%;
  margin-left: 0px;
  /* padding-right: 20px; */
}

.pricing-plan-purchase-btn {
  border: none !important;
  outline: none !important;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  width: 160px;
  height: 45px;
  border-radius: 22.5px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-pack: center;
  justify-content: center;
}
.pricing-plan-basic .pricing-plan-purchase-btn {
  background-color: #f5d028;
  color: #fff;
}
.pricing-plan-basic .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 #f5d028;
}
.pricing-plan-basic .pricing-plan-purchase-btn:active {
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  box-shadow: none;
}
.pricing-plan-pro .pricing-plan-purchase-btn {
  background-color: #10bb87;
  color: #fff;
}
.pricing-plan-pro .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 #0a7554;
}
.pricing-plan-pro .pricing-plan-purchase-btn:active {
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  box-shadow: none;
}
.pricing-plan-enterprise .pricing-plan-purchase-btn {
  background-color: #4453d6;
  color: #fff;
}
.pricing-plan-enterprise .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 #4453d6;
}
.pricing-plan-enterprise .pricing-plan-purchase-btn:active {
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  box-shadow: none;
}

/*# sourceMappingURL=pricing-plan.css.map */

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .priceListcontainer {
    /* margin-top: 150px !important; */
  }
}
@media all and (min-width: 768px) and (max-width: 1024px) {
  .priceListcontainer {
    /* margin-top: 200px !important; */
  }
}

@media all and (min-width: 1025px) {
  .priceListcontainer {
    display: flex;
    align-items: center;
    height: calc(100vh - 50px);

  }
}
@media all and (min-width: 1919px) {
  .priceListcontainer {
    width:calc(100vw - 300px);
    margin-left: 300px;

  }
}
