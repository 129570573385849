.marketplacebackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;
  margin-right: 31px;
}
.marketplacebackbutton:hover {
  background: transparent;
  color: #666666;
}
.marketplacebackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.marketplacebackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #666666;
}
.marketplacebackbutton img {
  width: 15px;
}
.welcometomarke {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #17171d;
  margin: 0px;
}
.searchporoinput {
  background: #c4c4c490;
  /* opacity: 0.4; */
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  padding-left: 26px;
  color: #333333;
  width: 764px;
  height: 49px;
  border-radius: 30px;
}

.searchporoinput::placeholder {
  color: black !important;
}

.searchporoinput :focus {
  background: #c4c4c4;
}
.SearchfortheM {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: #45443c;
}
.Marketplace {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  color: #333333;
  line-height: 35px;
}
.filtercircle {
  background: #17171d;
  opacity: 0.8;
  width: 49px;
  height: 49px;
  border-radius: 49px;
  text-align: center;
  margin-left: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchmarkebutton {
  width: 170px;
  height: 50px;
  background: #4453d6;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  border: none;
  margin-left: 8px;
  display: flex;
  align-items: center;
}
.searchmarkebutton p {
  padding-left: 18px;
  margin: 0px;
}
.marketplaceprovox {
  min-height: 133px;
  border-radius: 10px;
  margin-top: 20px;
  background: linear-gradient(212.39deg, #4453d6 14.84%, #571b65 69.92%);
  min-width: 700px;
  padding-top: 5px;
}
.markehgeading {
  width: 268px;
  height: 44px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-size: 16px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
}
.markehgeading span {
  font-weight: bold;
}
.markemaindiv {
  padding-left: 64px;
  padding-right: 64px;
}
.Marketplaceresults5 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #45443c;
  padding-left: 25px;
}
.Marketplaceresults5 span {
  color: #45443c;
  font-weight: bolder;
}
.markeimagebox {
  width: 208.22px;
  min-height: 299px;
  max-height: 320px;
  border-radius: 10px;
  margin-left: 16px;
  cursor: pointer;
  overflow: hidden;
  margin: 10px;
}
.markeimagebox .imageSize {
  width: 208.22px;
  height: 208.22px;
}
#marketplaceSearchsiemenavantomarke0 {
  height: 90px;
}
.siemenavantomarke {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  padding-left: 12px;
  line-height: 23px;
}
.siemenavantomarke span {
  font-size: 20px;
}
.DeviceType {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #e5e5e5;
  margin: 0px;
  padding-left: 12px;
}
.LocCafornian {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #e5e5e5;
  margin: 0px;
  padding-left: 12px;
}

.SliderBox {
  width: 208.22px;
  overflow: hidden;
  background: #17171d30;
  /* opacity: 0.3; */
  border-radius: 10px;
  padding-bottom: 10px;
  margin: auto;
  cursor: pointer;
  min-height: 302px;
}

/* .SliderBox::before {
  content: "";
  position: absolute;
  top:0;right:0;left:0;bottom:0;
  background: #17171D;
  opacity: 0.3;
  border-radius: 0px 0px 10px 10px;
  z-index: -1;
  width: 100%;
  height: 100%;
} */

.SliderBox .sliderProductImg {
  width: 208px;
  height: 200px;
}

.SliderBox .ProductBox {
  width: 150px;
  margin-left: 20px;
}
.p-button {
  background: transparent !important;
  border: 0px !important;
  color: #ffffff !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.p-carousel-prev span,
.p-carousel-next span {
  font-size: 50px !important;
}
.marketPlaceInnerContainer {
  height: calc(100vh - 85px);
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
  width: calc(100vw - 500px);
}
.productListView {
  display: inline-flex;
  margin: 10px;
  height: 500px;
  overflow-y: auto;
  flex-wrap: wrap;
}

.marketFilterWrapper {
  position: relative;
}

.marketficlosecircle {
  position: relative;
  z-index: 101;
}

.marketfilterbox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  background: #17171de6;
  border-radius: 30px;
  text-align: center;
  padding: 35px 40px;
  padding-bottom: 27px;
  z-index: 100;
}
/* .marketsearcyinput {
  background: #4453d6 !important;
  border-radius: 30px !important;
  width: 235px;
  border: none !important;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 10px;
} */


.marketfilterbox .p-dropdown-panel {
  top: 0 !important;
}

.p-dropdown-panel .p-dropdown-items-wrapper {
  height: 250px;
  padding: 10px 10px 20px 10px;
}
.p-dropdown-items-wrapper .p-dropdown-items {
  height: 100%;
  overflow-y: auto;
}
/* Scrollbar */
.p-dropdown-items::-webkit-scrollbar {
  width: 1rem !important;
  background: white;
  scrollbar-width: auto;
}

.p-dropdown-items::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white !important;
  width: 1rem;
}

.p-dropdown-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: grey;
}


.dropdownlablemarket {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.marketfilterbox .p-multiselect {
  margin-right: 0 !important;
  margin-top: 5px;
}

.marketficlosecircle {
  width: 49px;
  height: 49px;
  border-radius: 49px;
  background: #4453d6;
  text-align: center;
  margin: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.noNewitems {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
}


.marketplaceGlobalSearchDropdown {
  border-radius: 30px ;
  outline: none;
  height: 35px;
  width: 235px !important;
  padding: 20px !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  background: #4453D6;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-right: 10px;
}
.marketplaceGlobalSearchContainerDropdown {
  color: #17171D;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 35px;
  width: 235px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.marketplaceGlobalSearchDropdown > .select-box--selected-item{
  width: 100%;
  text-align: center;
}
.marketplaceGlobalSearchContainerDropdown > div{
  padding-left: 0px !important;
}

@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .marketplaceprovox {
    width: calc(100vw - 50px);
  }
  .marketPlaceInnerContainer {
    width: calc(100vw - 85px);
  }
}
@media only screen and (min-width: 1920px) {
  .marketPlaceInnerContainer {
    overflow: hidden;
    padding-bottom: 50px;
    width: calc(100vw - 800px);
    margin-left: 300px;
  }
  .marketplaceprovox {
    width: 1000px;
  }
  .marketplacebackbutton {
    margin-top: 20px;
  }
}
