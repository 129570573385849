.fileDriveContainer {
  width: calc(100vw - 0px);
  padding: 20px;
  height: calc(100vh - 80px);
  overflow: hidden;
  /* overflow-y: auto; */
  overflow-x: auto;
  padding-top: 0px !important;
  outline: none !important;
}
:focus {
  outline: none !important;
}

.vaultInnerContainer {
  /* height: calc(100vh - 100px); */
  /* width: calc(100vw - 200px); */
  width: 100%;
  min-width: 800px;
  max-width: 1300px;
}
.innerTableScroller {
  overflow-y: auto;
}

.fileuploading {
  width: 154px;
  height: 34px;
  background: #ffffff !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #333333 !important;
  cursor: pointer;
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  border: 0px !important;
  margin-left: 30px;
  padding-left: 10px;
  margin-bottom: 4px;
}
.fileuploading img {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.myVaultTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 34px;
  color: #ffffff;
  align-items: center;
  display: inline-flex;
}
.myVaultTitle span {
  font-weight: bold;
  margin-left: 7px;
  margin-right: 8px;
}

.uploadingMainBox {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin: 3px;
  align-items: center;
  text-align: center;
}
.uploadingList {
  display: inline-flex;
  justify-content: space-between;
  /* padding: 10px;
  border-radius: 10px;
  margin: 3px; */
  /* background: #333333; */
  align-items: center;
  text-align: center;
}
.dicomSpecification {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  /* margin-top: 7px; */
  /* padding-top:5px; */
}
.dicomSpecificationDetailBox {
  display: inline-flex;
  justify-content: flex-start;
}
.dicomSpecificationText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  margin-right: 30px;
  text-align: left;
  color: #ffffff;
  width: 175px;
}
.dicomSpecificationGrid {
  width: 405px;
}
.dicomSpecificationGridBox {
  display: inline-flex;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
}
.dicomSpecificationGridLabel {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  font-size: 13px;
  min-width: 100px;
  text-align: left;
}
.dicomSpecificationGridValue {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  font-size: 13px;
  min-width: 200px;
  text-align: left;
}
.uploadingListHeader {
  background: transparent !important;
  margin: 10px !important;
  padding: 0px !important;
  margin-left: 15px !important;
}
.uploadingList .fileTypeBox {
  min-width: 60px;
  margin-right: 10px;
}

.uploadingListHeader .headerNameTitle {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  min-width: 350px;
  margin: auto;
  display: inline-flex;
  max-width: 450px;
  text-align: left;
}
.uploadingListHeader .headerDateTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  color: #ffffff;
  min-width: 250px;
  display: inline-flex;
}

.uploadingListHeader .headerFileSizeTitle {
  min-width: 200px;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  display: inline-flex;

  color: #ffffff;
}
/* .uploadProgressBar {
  width: 250px;
  height: 7px;
  background: #c4c4c4;
  border-radius: 10px;
} */
.uploadProgressBar {
  width: 210px;
  height: 10px !important;
  background: #c4c4c4;
  border-radius: 10px;
  margin-right: 10px;
}
.uploadProgressBar .progress-bar {
  background-color: #4aea87 !important;
}

.fileNameText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  min-width: 350px;
  margin: auto;
  display: inline-flex;
  max-width: 350px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 15px;
}
.fileDateBox {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  color: #ffffff;
  min-width: 250px;
  display: inline-flex;
}
.fileSizeBox {
  min-width: 200px;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  display: inline-flex;
  color: #ffffff;
}
.fileActionBox {
  min-width: 100px;
  display: inline-flex;
  align-items: center;

  justify-content: flex-end;
  padding-right: 8px;
}
.shareFileButton {
  background: #4453d6 !important;
  border-radius: 30px;
  width: 95px;
  height: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border: 0px !important;
  color: #ffffff !important;
  /* margin-top: 20px; */
}
.fileStatusBox {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  min-width: 290px;
  margin: auto;
  display: inline-flex;
  justify-content: flex-end;
}
.fileMenuOption {
  background: transparent !important;
  border: none !important;
  /* font-size: 30px; */
  color: #ffffff !important;
  padding: 0px;
  margin-left: 15px !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
}
.fileMenuOption img {
  width: 25px;
  height: 25px;
}
.dicomfileViewOption {
  background: transparent !important;
  border: none !important;
  padding: 0px;
}

.filterFileButton {
  background: transparent !important;
  border: none !important;
  color: #ffffff !important;
  padding: 0px;
  margin-left: 10px;
}
.filterFileButton img {
  width: 15px;
  height: 10px;
}

.viewFileDocument {
  background: #4453d6 !important;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  border: none !important;
  margin-left: 15px;
  height: 35px;
  line-height: 0px;
  border-radius: 10px;
}
.loadingFiles {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}
.topFileHeader {
  display: inline-flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  min-width: 600px;
  /* width: calc(100vw - 100px); */
  align-items: center;
  padding-left: 1px;
  margin-bottom: 0px;
  margin-top: 10px;
  max-width: 1300px;
  padding-right: 8px;
}
.vaultFilters {
  width: 270px;
  text-align: center;
  padding-top: 95px;
}
.vaultFiltersButton {
  display: inline-flex;
  background: transparent !important;
  border: none !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff !important;
  align-items: flex-end;
}

.searchFileinput {
  width: 338px;
  height: 38px;
  border-radius: 30px;
  padding-left: 35px;
  color: #ffffff !important;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  background: #17171d;
  opacity: 0.5;
  border-radius: 30px;
  padding-right: 70px;
}
.searchFileinput::placeholder {
  color: #ffffff !important;
  font-family: Segoe !important;
  font-style: italic !important;
  font-weight: normal !important;
  font-size: 14px;
}
.searchImageIcon {
  width: 20px;
  height: 20px;
  position: relative;
  left: 28px;
  z-index: 1;
  margin: auto;
}

.addVaultPopupBoxArrow {
  position: absolute;
  right: 50px;
}

.addVaultPopup {
  display: block;
  position: absolute;
  width: 200px;
  height: max-content;
  border-radius: 10px;
  z-index: 100;
  top: 10px;
  right: 0;
  left: -120px;
  bottom: 0;
  background: #ffffff;
  padding: 15px;
}
.nameFilterVaultPopup {
  display: block;
  position: absolute;
  width: 250px;
  height: max-content;
  border-radius: 10px;
  z-index: 100;
  top: 5px;
  right: 0;
  left: 60px;
  bottom: 0;
  background: #ffffff;
  padding: 15px;
}
.nameGridFilterVaultPopup {
  display: block;
  position: absolute;
  width: 250px;
  height: max-content;
  border-radius: 10px;
  z-index: 100;
  top: 5px;
  right: 0;
  left: 0px;
  bottom: 0;
  background: #ffffff;
  padding: 15px;
}
.filterVaultRecentPopup {
  display: block;
  position: absolute;
  width: 250px;
  height: max-content;
  border-radius: 10px;
  z-index: 100;
  top: 10px;
  right: 0;
  left: 0px;
  bottom: 0;
  background: #ffffff;
  padding: 10px;
}
/* .addVaultPopup::before {
  content: "";
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  background: #FFFFFF;
  border-radius: 10px;
  opacity: 0.8;
  z-index: -1;
} */

.listText {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  display: inline-flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px dotted #a2a2a5;
}

.listedIcons {
  width: 23px;
  height: 20px;
  margin-right: 15px;
}

.checkBoxDiv {
  width: 40px;
}
.checkBoxDiv .checkBlueimage {
  width: 20px;
  height: 15px;
}

.shareFilesModalBox {
  background: #ffffff;
  border-radius: 30px;
  min-width: 391px;
  /* padding: 25px; */
}

.closeFileshareModal {
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
}

.shareFilesModalBox .confirmTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #333333;
  margin-bottom: 5px;
}

.addNewfolderModalBox {
  background: #ffffff;
  border-radius: 10px;
  min-width: 340px;
  min-height: 190px;
  text-align: center;
}
.addNewfolderModalTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 10px;
  color: #ffffff;
  text-align: left;
  padding-left: 0px;
  color: #17171d;
}
.addNewfolderinput {
  width: 290px;
  height: 42px;
  border-radius: 30px;
  color: #ffffff !important;
  background: #4453d6 !important;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 20px;
}
.addNewfolderinput::placeholder {
  color: #ffffff !important;
  opacity: 0.7;
}
.copylinkTitle {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-indent: 50px;

  color: #333333;
  display: inline-flex;
}
.fileShareContainer {
  min-height: 200px;
  height: calc(100vh - 400px);
  overflow: hidden;
  overflow-y: auto;
}
.fileShareContainer::-webkit-scrollbar-track {
  background: #dddddd !important;
  border-radius: 10px;
}
.fileShareContainer::-webkit-scrollbar-thumb {
  background: #17171d !important;
  border-radius: 10px;
}

.fileShareContainer::-webkit-scrollbar {
  width: 6px !important;
}

.searchFileMembers {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  margin-top: 10px;
  opacity: 0.8;
  color: #333333;
}

.shareFilememberList {
  display: inline-flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  /* cursor: pointer; */
  /* justify-content: space-between; */
}
.shareUserimageBox {
  /* width: 40px;
  height: 40px;
  border: 2px solid #4453D6;
  overflow: hidden; 
  border-radius:100px; */
  position: relative;
}
.shareUserimageBox::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  border: 2px solid #4453d6;
  overflow: hidden;
  border-radius: 100px;
}

.shareUserimage {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  /* border: 2px solid #4453D6; */
}
.shareUsername {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #333333;
}
.shareUserLastSeen {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #c4c4c4;
}

.searchinputShareuser {
  background: #4453d6;
  border-radius: 30px;
  width: 327px;
  height: 49px;
  display: flex;
  align-items: center;
}
.findUserSearch {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff !important;
  background: transparent !important;
  width: 280px;
  border: none;
  height: 40px;
  padding-left: 10px;
}
.findUserSearch:focus {
  background: transparent;
}

.vaultOptionModal {
  background: #ffffff;
  border-radius: 10px;
  width: 456px;
  min-height: 100px;
  padding-right: 0px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.vaultOptionModal .confirmTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #333333;
  margin-bottom: 5px;
  margin-left: 8px;
}
.sharingOptions {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  display: inline-flex;
  color: #333333;
  align-items: center;
  align-items: center;
  width: 202px;
  cursor: pointer;
  border-bottom: 1px dotted #a2a2a5;
  padding-bottom: 10px;
  margin-left: 4px;
  margin-right: 8px;
  margin-top: 10px;
  padding-left: 4px;
}

.sharingOptions img {
  margin-right: 10px;
}
.manageOptionContainer {
  margin: auto;
  flex-wrap: wrap;
}
.alignItem {
  display: inline-flex;
  align-items: center;
  width: max-content;
}
.alignItem .overflowFolderText {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.noVaultFile {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  text-indent: 50px;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
}
.addnewVaultbusiness {
  background: #17171d !important;
  border: none !important;
  border-radius: 30px;
  color: #ffffff !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  height: 50px;
  width: 200px;
  padding: 10px;
  margin-top: 20px;
}
.createFolder {
  background: #17171d !important;
  border-radius: 30px;
  color: #ffffff !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  width: 102px;
  height: 37px;
}
.cancelCreateFolder {
  border: 1px solid #17171d !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  width: 102px;
  color: #333333 !important;
  background: #ffffff !important;
  height: 37px;
}
.sharePopupFileButton {
  background: #17171d;
  border-radius: 30px;
  color: #ffffff !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  width: 100%;
  height: 50px;
  opacity: initial !important;
}
.removeFromShare {
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff !important;
  width: 90px;
  height: 35px;
  border: none !important;
}
.hiddenCopy {
  width: 5px;
  color: #ffffff !important;
  height: 0px;
}

.backTodashboardVault {
  background: #17171d !important;
  color: #ffffff !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-top: 20px;
  border: none !important;
  border-radius: 30px;
  width: 200px;
}
.rdee-vaultTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 65px;

  color: #ffffff;
}
.rdee-vaultSubTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 24px;
  padding-left: 1px;
  color: #ffffff;

  opacity: 0.8;
}
.dropBoxMainArea {
  width: 350px;
  background: #4453d6;
  height: 150px;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}
.dropBoxArea {
  position: absolute;
  bottom: 150px;
  width: 100%;
  align-items: center;
  display: inline-flex;
  justify-content: center;

  color: #ffffff;
  align-items: center;
}
.dropFiletitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding-left: 1px;
  color: #ffffff;
}
.selectFileButton {
  background: transparent !important;
  border: none !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #ffffff !important;
  font-size: 18px;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 0px;
}
.rotateArrow180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.folderStackButton {
  width: max-content;
  background: #333333 !important;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  border: none !important;
  padding: 0px !important;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  letter-spacing: 2px;
}
.stackFilenameBox {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.stackFilenameBox .folderStackOpenContent {
  display: block;
  position: absolute;
  min-width: 200px;
  height: max-content;
  border-radius: 10px;
  z-index: 100;
  top: 40px;
  right: 0;
  left: 0px;
  bottom: 0;
  background: #ffffff;
  padding: 15px;
}
.stackFilenameBox .overFlowFolderName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.shareDriveConfirm {
  background: #ffffff;
  border-radius: 30px;
  padding: 23px;
}
.shareDriveConfirm .confirmmsgText {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
}
.shareDriveConfirm .confirmTitle {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
}
.shareDriveConfirm .buttons {
  min-width: 100px;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  border-radius: 30px;

  border-radius: 30px;
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #ffffff !important;
  border: 0px;
}
.shareDriveConfirm .yesButton {
  background: #17171d !important;
  /* border-radius: 30px; */
  margin-left: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: none !important;
}
.shareDriveConfirm .nobutton {
  border: 1px solid #17171d !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  width: 102px;
  color: #333333 !important;
  background: #ffffff !important;
  height: 37px;
}
.shareDriveConfirm .buttonDiv {
  display: inline-flex;
  float: right;
  margin-top: 15px;
}

.shareDriveConfirm .buttonDivCopyLink {
  text-align: center;
}

.shareDriveConfirm .copyClipboardButton {
  text-align: center;
  height: 37px;
  width: 207px;
  border-radius: 30px;
  color: #ffffff !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border: 0px !important;
}
.confirmCodeFileInput {
  width: 250px;
  height: 42px;
  border-radius: 30px;
  color: #ffffff !important;
  background: #4453d6 !important;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 20px;
}
.confirmCodeFileInput::placeholder {
  color: #ffffff !important;
  opacity: 0.7;
}

.viewFileConfirmBtnBox {
  text-align: center;
}

@media only screen and (min-width: 1920px) {
  .fileDriveContainer {
    width: calc(100vw - 300px);
    padding-left: 40px;
    padding-bottom: 40px;
    margin-left: 300px;
  }
  .topFileHeader {
    width: calc(100vw - 400px);
    max-width: 1280px;
    padding-right: 0px;
  }
  .fileNameText {
    min-width: 400px;
  }
  .uploadingListHeader .headerNameTitle {
    min-width: 400px;
  }
  .vaultInnerContainer {
    width: calc(100vw - 200px);
    min-width: 1280px;
    max-width: 1280px;
  }
}
